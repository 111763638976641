// Main Navigation
//////////////////////////////
nav {
	.col {
		padding: 0;

		@include breakpoint(large) {
		    padding-left:1rem;
		    padding-right:1rem;
		}
	}
}
.navi-main {
	margin-bottom: 3rem;
	@include breakpoint(large) {
	    display:flex; 
	    justify-content:space-between;
	    width: 100%;
	    padding: 0 2rem;
	    margin-bottom: 0;
	}
	li {
		position: relative;
		&:hover {
			a {
				text-decoration: underline;
				color: $primary;
			}
		}

		&.active {
			a {
				font-weight: 700;
			}
		}

		a {
			background: $light;
			display: block;
			padding: .5rem 1rem;
			margin-bottom: .1rem;
			text-decoration: none;
			@include breakpoint(large) {
			    padding: 2rem 0;
			    background: transparent;
			}
		}

		&.hasSub {
			a {
				margin-bottom: 0;
			}
		}

		&.technischer-notar, 
		&.technische-vermessung, &.vermessung  {
			a {
				background: $vermessung;
				color: $light;
				@include breakpoint(large) {
				    color: $primary;
				    background: transparent;
				}
			}
		}

		&.mediation, 
		&.vertrauensperson {
			a {
				background: $mediation;
				color: $light;
				@include breakpoint(large) {
				    color: $primary;
				    background: transparent;
				}
			}
		}

		@include breakpoint(large) {
			&.startseite,
			&.kontakt, 
			&.anfahrt, 
			&.impressum, 
			&.datenschutzerklaerung {
				display: none;
			}
		}

		&:hover ul {
			display: block;
		}


		.sub {
			margin-bottom: .1rem;
			@include breakpoint(large) {
			    display: none;
			    position: absolute;
			    background: $light;
			    padding: .5rem 1rem;
			    width: 12rem;
			}

			li {

				a {
					font-size: 90%;
					padding: .2rem 1rem;
					text-decoration: none;
					margin-bottom: 0;
					font-weight: 300;

					&:hover, &.active {
						text-decoration: underline;
					}

					@include breakpoint(large) {
					    padding: .4rem 0;
					    font-size: 95%;
					}
				}
			}
		}
	}
}

.navi-add {
	display: none;
	@include breakpoint(large) {
	    display: block;
	}
	li {
		a {
			text-decoration: none;
			color: $light;
			&:hover {
				text-decoration: underline;
			}
			&.active {
				font-weight: 700;
			}
		}

		.sub {
			padding-bottom: .7rem;
			li {
				a {
					font-size: 85%;
				}
			}
		}
	}
}

// Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	background:$background;
	left:-100%;
	overflow-y: auto;
	position: fixed;
	top:rem($menu-bar);
	transition:.6s;
	width:map-get($breakpoints, tiny) * .8;
	z-index: 895;
    bottom: 0;
    padding: 2rem;
    max-width:100%;

    .branding-vermessung, .branding-mediation {
    	display: none;
    }

	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(large) {
		background: transparent;
		overflow: visible;
		overflow-y: initial;
		bottom:auto;
		left:auto;
		position: fixed;
		top:auto;
		width: 100%;
		z-index: 160;
		padding: 0;

		&.fixed {
			background: rgba(255,255,255,.9);

			.navi-main {
				padding: 0;
				padding-left: .5rem;
				float: left;

				li {
					a {
						padding: 1rem 0;
					}

					&.hasSub {
						a {
							
							width: 126px;
							height: 69px;
							text-indent: -9999px;
							font-size: 0;
							&.vermessung {
								background: image-url('logo-vermessung-klein.png') 0 12px no-repeat;
							}
							&.mediation {
								background: image-url('logo-mediation-klein.png') 0 12px no-repeat;
							}
						}

						.sub {
							li {
								a {
									background: transparent;
									width: auto;
									height: auto;
									text-indent: 0;
									padding: .4rem 0;
									font-size: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(giant) {
	    &.fixed {
			.branding-vermessung, .branding-mediation{
				float: left;
				display: inline-block;
				margin: .5rem .4rem;
			}

	    }
	}

	.kontakt-box {
		z-index: 140;
		position: relative;
		div {
			background: $light;
			color: $primary;
			transition: 300ms all ease-in-out;
			margin-bottom: .5rem;
			padding: 1.25rem 1rem 1.25rem 4rem;
			position: relative;
			font-size: 1.2rem;
			font-family: $display-font;
			line-height: 1rem;

			&.home {
				.adresse-box {
					.text {
						margin-bottom: .5rem;
						font-family: $main-font;
						display: block;
						font-size: 1rem;
						line-height: 1.3rem;
					}
				}
			}
			
			@include breakpoint(large) {
			    position: fixed;
			    right: 0;
			    width: initial;
			    transform: translate(100%, 0);
				white-space: nowrap;
				padding: 1.25rem 1rem 1.25rem 1rem;

			    &:hover {
			    	transform: none;
			    }

				&.home {top: 17.8rem;}
				&.tel {top: 10rem;}
				&.mail {top: 13.9rem;}
			}

			&:before {
				font-family: $icon-font;
				font-style: normal;
				font-size: 1.4rem;
				background: $light;
			    width: 3.5rem;
			    height: 3.5rem;
			    line-height: 3.5rem;
			    text-align: center;
			    display: block;
			    position: absolute;
			    top: 0;
			    left:0;

			    @include breakpoint(large) {
			        left: auto;
			    	right: 100%;
			    	cursor: pointer;
			    }
			}

			&.home {&:before {content:"\f015";}}
			&.tel {&:before {content:"\f095";}}
			&.mail {&:before {content:"\f0e0";}}

			p {
				margin: 0;
			}

			a {
				color: $primary;
				text-decoration: none;
			}

			&:hover {
				right: 0;
				z-index: 101;
			}
		}
	}

}

.mobile-bar {
	align-items:center;
	background:$light;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 894;

	li {
		display:flex;
		flex-grow:1;
		padding:0 rem($base-gap);

		&:last-child {
            justify-content:flex-end;
        }
	}

	@include breakpoint(large) {
		display: none;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
	}

	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .page-navi,
		~ .page-navi {
			left:0;
		}
	}
}

body.iexplore {
	.kontakt-box {
		div {
			&:before {
				padding-top: 1.7rem;
			}
		}
	}
}