// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
	}

	dd {
		width: 60%;
	}

	&.index {
		dt {
			width: 18%; 
			font-weight: 700;
		}

		dd {
			width: 82%;
		}
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn, a.btn {
	background: $alert;
	border: none;
	color: $light;
	display: table;
	font-size: 1.2rem;
	font-family: $display-font;
	line-height: 1rem;
	padding: 1rem 1.6rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;
	margin-bottom: 5.5rem;
	position: relative;

	i {
		font-family: $icon-font;
		font-style: normal;
	}

	a {
		text-decoration: none;
	}

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top:rem($menu-bar);
	
	@include breakpoint(large) {
		padding-top:0;
	}
}

// HEADER
//////////////////////////////

.header {
	position: relative;
	width: 100%;
	background: image-url('bg-header.png') center -141px no-repeat;

	@include breakpoint(tiny) {
	    background-position: center -101px;
	}

	@include breakpoint(large) {
	    background-position: center top;
	}

	.branding {
		max-width: rem(359px);
		max-height: rem(359px);
		display: block;
		padding-top: 1rem;
		margin: 0 auto;
		position: relative;
		z-index: 100;

		img {
			max-width: rem(359px);
			max-height: rem(359px);
		}

		@include breakpoint(tiny) {
		    margin-bottom: 1rem;
		}

		@include breakpoint(medium) {
		    margin: 0 auto 1rem auto;
		}

		@include breakpoint(large) {
		    padding-top: 6.6rem;
			margin: 0 auto 6.6rem auto;
		}
	}

	.branding-vermessung, .branding-mediation {
		max-width: rem(126px);
		max-height: rem(44px);
		margin-bottom: 2rem;
		float: left;
		margin-top:-1rem;
		position: relative;
		z-index: 120;

		img {
			max-width: rem(126px);
			max-height: rem(44px);
		}

		@include breakpoint(medium) {
		    float: right;
		    margin-right: 3rem;
		}
	}

	.branding-mediation {
		float: right;
		@include breakpoint(medium) {
			margin-right: 0;
			margin-left: 3rem;
			float: left;
		}
	}

}

	/*.kontakt-box {
		position: relative;

		.btn, a.btn {
			background: $light;
			color: $dark;
			position: relative;
			text-align: left;
			margin-bottom: .1rem;
			width: 100%;
			i {
				padding-right: .6rem;
				float: left;
			}

			.adresse-box {
				float: left;

			    span.text {
			    	font-weight: 300;
			    	font-size: 1rem;
			    	font-family: $main-font;
			    	line-height: 1.5rem;

			    	&:first-child {
			    		margin-bottom: .8rem;
			    	}
			    }
			}

			a {
				color: $dark;
			}
		}

		@include breakpoint(large) {
		    

		    .btn, a.btn {
		    	padding: 1.15rem 1.2rem;
		    	height: 3.35rem;
		    	font-size: 1.2rem;
		    	transform: translate(100%, 0);
		    	white-space: nowrap;
		    	position: fixed;
			    right: 0;
			    z-index: 130;
			    width: initial;

		    	&, &:before {
		    		background-color: $light;
		    	}

		    	&:before {
		    		font-family: $icon-font;		    		
		    		font-size: 1.6rem;
		    		position: absolute;
		    		width: 3.35rem;
		    		height: 3.35rem;
		    		line-height: 3.35rem;
		    		top: 0;
		    		right: 100%;
		    		text-align: center;		    		
		    		display: block;
		    		cursor: pointer;
		    	}

		    	span {
		    		display: block;
		    	}

		    	&:hover {
					transform: none;		    		
		    	}

		    	&.tel {
		    		top: 8rem;

		    		&:before {
		    			content: "\f095";
		    		}
		    	}

		    	&.mail {
					top: 11.6rem;

					&:before {
						content: "\f0e0";
						// padding: 1.13rem 1rem;
						// font-size: 1.3rem;
					}
		    	}

		    	&.adresse {
					top: 15.2rem;
					height: auto;

		    		&:before {
		    			content: "\f015";
		    		}
		    	}
		    }
		}
	}*/

// MAIN CONTENT
//////////////////////////////
main {
	display: block;
	padding: 2.5rem 0;
	background: $background;

	.mediation-pdf, .vermessung-pdf {
		margin: 0 1rem;
	}

	.mediation, .vermessung {
		.col {
			position: relative;
		}
	}

	.vermessung {
		@include breakpoint(large) {
		    width: rem(990px);
		    height: 1608px;
		    padding-top: 8rem;

		    .vermessung-pdf {
		    	position: absolute;
				right: 3.2rem;
			    top: 43.5rem;
		    }
		}

		@include breakpoint(giant) {
		    width: rem(1116px);
		    .vermessung-pdf {
		    	right: 6.7rem;
		    }
		}

		@include breakpoint(huge) {
		    width: rem(2200px);
		    .vermessung-pdf {
		    	right: 8.9rem;
		    }
		}

		.branding-vermessung {
			max-width: auto;
			width:auto;
			display: block;
			margin-bottom: 5rem;

			img {
				max-width: auto;
				width:auto;
			}
		}


		.btn {
			background: $vermessung;
			&:hover, &:active, &:focus {
				background: $dark;
				color: $light;
			}
		}

		@include breakpoint(huge) {
		    .vermessung-pdf {
		    	left:46.5rem;
    			top: 44.5rem;
		    }
		}

		h2 {
			color: $vermessung;
			strong {
				font-weight: 600;
				text-transform: uppercase;
			}
		}
	}

	.mediation {
		padding-top: 140px;

		@include breakpoint(large) {
		    width: rem(990px);
		    height: 1621px;

		    .mediation-pdf {
		    	position: absolute;
				left: 1.9rem;
				top: 34.6rem;
		    }
		}

		@include breakpoint(giant) {
		    width: rem(1116px);
		    .mediation-pdf {
		    	left: 5.5rem;
		    }
		}

		@include breakpoint(huge) {
		    width: rem(2200px);
		    .mediation-pdf {
		    	left: 7.8rem;
		    }
		}



		.branding-mediation {
			display: block;
			margin-bottom: 5rem;
			float: left;
			max-width:100%;
			width:100%;

			img {
				max-width: auto;
				width:auto;
				float: left;
				@include breakpoint(large) {
				    float: right;
				}
			}
		}

		.btn {
			background: $mediation;
			&:hover, &:active, &:focus {
				background: $dark;
				color: $light;
			}

			@include breakpoint(large) {
			   float: right;
			}
		}


		h2 {
			color: $mediation;
			strong {
				font-weight: 600;
				text-transform: uppercase;
			}
		}
	}


	ul {
		@extend .styled-list;
	}

	.google-maps {
		@include breakpoint(medium) {
			height: rem(350px)!important;
			margin-bottom: .6rem;

			iframe {
				height: rem(350px)!important;
			}
		}
	}
}

body.index {
	.header {
		.claim {
			a {
				text-decoration: none;
				i {
					font-style: normal;
					font-family: $icon-font;
					font-size: 2rem;
					color: $dark;
					text-align: center;
					width: 100%;
					display: block;
					margin-top: 2rem;
					margin-bottom: 2rem;
				}
			}
			.row:first-child {
				background: image-url('bg-claim.png') center top no-repeat;
				padding-bottom: 0.5rem;

				.col {
					padding-left: 0.5rem;
					padding-right: 0.5rem;

					@include breakpoint(tiny) {
					    padding-left: 1rem;
						padding-right: 1rem;
					}
				}

			}
		}

		.branding-vermessung, .branding-mediation {
			max-width: rem(126px);
			max-height: rem(44px);
			margin-bottom: 8rem;
			float: left;
			margin-top:-1rem;
			position: relative;
			z-index: 120;

			img {
				max-width: rem(126px);
				max-height: rem(44px);
			}

			@include breakpoint(medium) {
			    float: right;
			    margin-right: 3rem;
			}
		}

		.branding-mediation {
			float: right;
			@include breakpoint(medium) {
				margin-right: 0;
				margin-left: 3rem;
				float: left;
			}
		}
	}
	main {
		@include breakpoint(large) {
		    background: transparent image-url('bg-body.png') center top no-repeat;
		}

		@include breakpoint(giant) {
		    background: transparent image-url('bg-body-giant.png') center top no-repeat;
		}
	}
}


// FOOTER
//////////////////////////////

.footer {
	display: block;
	background: #0e0d0e;
	color: $light;
	padding: 3rem 0 1.5rem;


	a {
		color: #db021e;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(large) {
	    background: transparent image-url('bg-footer.png') top center no-repeat;
	   	padding-top: 20rem;
	    padding-bottom: 2rem;
	}
}